import React, { useEffect } from "react";
import "./TeamPage.css";
import backgroundImage from '../../public/hand.png'

function Team() {
  useEffect(() => {
    // Set the background image when the component mounts
    document.body.style.backgroundImage = `url(${backgroundImage})`;
    document.body.style.backgroundSize = 'cover'; // Cover the entire page
    document.body.style.backgroundRepeat = 'no-repeat'; // Do not repeat the image
    document.body.style.backgroundAttachment = 'fixed'; // Fixed background

    // Optional: Reset the background image when the component unmounts
    return () => {
      document.body.style.backgroundImage = null;
      document.body.style.backgroundSize = null;
      document.body.style.backgroundRepeat = null;
      document.body.style.backgroundAttachment = null;
    };
  }, []);


  return (
    <div style={{color:'white',  fontSize: '18px', fontFamily:'Univers'}} className="team-page-container">
      <div className="member-container">
      <div className="member-name-title-container">
        <div className="member-name">
          <strong>CHARBEL MATTA</strong>
        </div>
        <div className="member-title" >
           Founder / Managing Partner
        </div>
        </div>
        <div className="member-info">
          <h2>Certifications</h2>
          <ul>
            <li>Lebanese Certified Public Accountant (LACPA) and Sworn Court Expert</li>
          </ul>
          <h2>Educational Background</h2>
          <ul>
          <li>Bachelor of Business Administration in Accounting <br /> Pigier, Beirut, Lebanon</li>
           <br />
          <li>Informatique de Gestion<br /> Institut Universitaire de Technologie, Orsay, Paris, France</li>
          </ul>
          <h2>Professional Background</h2>
          <ul>
          <li>Matta Financial Services - Founder/Managing Partner <br /> Byblos, Lebanon</li> <br />
          <li>Sabeh Khalil Accounting and Auditing - Senior Auditor<br /> Beirut, Lebanon 1986-2006</li> <br />
          <li>Antoine Daher Accounting and Auditing - Auditor<br /> Beirut, Lebanon 1984-1985</li>
          </ul>
        </div>
      </div>
      
      <div className="member-container">
      <div className="member-name-title-container">
      <div className="member-name">
      <strong>JOE MATTA</strong>
        </div>
        <div className="member-title">
          Manager
        </div>
        </div>
        <div className="member-info" style={{paddingBottom: '35px', marginBottom: '35px'}}>
          <h2>Certifications</h2>
          <ul>
            <li>US Certified Public Accountant (US CPA)</li> 
            <li>Lebanese Certified Public Accountant (LACPA)</li>
          </ul>
          <h2>Educational Background</h2>
          <ul>
          <li>Bachelor of Business Administration in Accounting <br /> Lebanese American University (LAU), Byblos, Lebanon</li> 
          </ul>
          <h2>Professional Background</h2>
          <ul>
          <li>Matta Financial Services - Manager <br /> Byblos, Lebanon </li> <br />
          <li>Ernst & Young (EY) - Senior Auditor<br /> Beirut, Lebanon 2019-2023</li>
          </ul>
        </div>




      </div>
      
    </div>
  );
}

export default Team;