// LayoutWithHorizontalTabs.js
import React from 'react';
import NavigationTabs from './HorizontalTabs';
import { Outlet } from 'react-router-dom';

function LayoutWithHorizontalTabs() {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <NavigationTabs />
      <main style={{ flexGrow: 1, overflowY: 'auto' }}>
        <Outlet />  
      </main>
    </div>
  );
}

export default LayoutWithHorizontalTabs;
