import React, { useState, useEffect } from "react";
import './HomePage.css';
import mobileImage from '../../public/home.png'; // The image you want to display on mobile

function HomePage() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    // Function to update state based on screen size
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Set background image for desktop
    if (!isMobile) {
      document.body.style.backgroundImage = `url(${mobileImage})`;
      document.body.style.backgroundSize = 'cover';
      document.body.style.backgroundRepeat = 'no-repeat';
      document.body.style.backgroundAttachment = 'fixed';
    }

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
      document.body.style.backgroundImage = null;
      document.body.style.backgroundSize = null;
      document.body.style.backgroundRepeat = null;
      document.body.style.backgroundAttachment = null;
    };
  }, [isMobile]);

  const backgroundStyle = {
    backgroundImage: isMobile ? '' : `url(${mobileImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    minHeight: '100vh', // Make sure the div is at least the height of the viewport
  };

  const textContent1 = `Established in 2007 by Charbel Matta, a Lebanese certified public
  accountant and sworn court expert, MATTA Financial Services has
  evolved into a distinguished audit and consulting firm. Over the years,
  strategic growth initiatives have seen the addition of new partners
  and managers, including US Certified Public Accountants, enriching
  our team with diverse expertise.`;

  const textContent2 = `At MATTA Financial Services, we take pride in our commitment to 
  excellence and client-centric approach. Currently, we cater to a 
  robust customer portfolio, with over 60 active domestic companies  
  located throughout Lebanon. Additionally, our reach extends globally, 
  serving more than 5 active international enterprises.`;

  const textContent3 = ` Our journey is marked by a relentless pursuit of professional integrity, 
  innovation, and tailored solutions. As we continue to expand our 
  horizons, MATTA Financial Services remains dedicated to providing 
  unparalleled audit and consulting services, contributing to the 
  success of businesses both locally and on the international stage.`;

  return (
    <div className="home-container">
      {isMobile && (
        <div style={{ textAlign: 'center' }}>
          <img src={mobileImage} alt="Background" style={{ maxWidth: '100%', height: 'auto', borderRadius: '10px' }} />
        </div>
      )}
      <div className="text-content">
        <p>{textContent1}</p>
       
        <br />
   
      <p>{textContent2}</p>
       
       <br />
      
      <p>{textContent3}</p>
      
      </div>
          
    </div>
  );
}

export default HomePage;