import React, { useEffect } from "react";
import "./Services.css";
import image1 from '../../public/accounting.png'
import image2 from '../../public/advisor.png'
import image3 from '../../public/audit.png'
import image4 from '../../public/taxes.png'
import image5 from '../../public/formation.png'
import image6 from '../../public/Cost.jpg'

// Dummy data for the services
const servicesData = [
  {
    title: 'Audit and Assurance',
    description: 'Our team of experienced professionals conducts thorough audits to ensure the accuracy and transparency of financial records. We help businesses adhere to regulatory requirements and industry standards while identifying areas for improvement.',
    imageUrl: image3,
  },
  {
    title: 'Tax Consulting',
    description: 'We provide expert guidance on tax planning, compliance, and optimization strategies. Our aim is to help businesses minimize their tax liabilities while staying compliant with ever-changing tax laws.',
    imageUrl: image4,
  },
  {
    title: 'Accounting',
    description: 'We assist in maintaining accurate and up-to-date accounting records, financial statements, and reports. Our services aid in facilitating better financial management and decision making.',
    imageUrl: image1,
  },
  {
    title: 'Cost Control',
    description: 'At our company, we specialize in helping you tackle the challenge of high food costs head-on. We believe in long-term solutions, not quick fixes like raising prices or cutting corners on quality. Our approach involves a thorough examination of your menu, streamlining operations, analyzing your ingredient expenses, and more. These essential steps lay the foundation for increasing your profits and expanding your business. Trust our team to provide you with valuable guidance to regain control over your F&B costs.',
    imageUrl: image6,
  },
  {
    title: 'Financial Advisory',
    description: 'Our financial experts offer strategic advice to help clients make informed decisions related to investments, mergers and acquisitions, capital raising, and other financial matters.',
    imageUrl: image2,
  },
  {
    title: 'Company Formation',
    description: 'Assisting clients in the process of establishing new companies, from legal and regulatory compliance to structural setup.',
    imageUrl: image5,
  } 
];

function Services() {
  useEffect(() => {
    // When the component mounts, set the background color of the body
    document.body.style.backgroundColor = "grey"; // Replace with your desired color

    // When the component unmounts, reset the background color
    return () => {
      document.body.style.backgroundColor = ""; // Reset to default color
    };
  }, []);
  return (
    <div className="services-grid">
      {servicesData.map((service, index) => (
        <div className="service-card" key={index}>
          <img src={service.imageUrl} alt={service.title} className="service-image" />
          <div className="service-info">
            <h3>{service.title}</h3>
            <p>{service.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
}


export default Services;