import React, { useEffect } from "react";

import './ContactPage.css';
import PhoneIcon from '@mui/icons-material/Phone';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import EmailIcon from '@mui/icons-material/Email';
import PlaceIcon from '@mui/icons-material/Place';
import backgroundImage from '../../public/contact.png'

function ContactUs() {
  const googleMapsUrl = `https://maps.app.goo.gl/PG8WpkeggUaKpp8Q7`;

  useEffect(() => {
    // Set the background image when the component mounts
    document.body.style.backgroundImage = `url(${backgroundImage})`;
    document.body.style.backgroundSize = 'cover'; // Cover the entire page
    document.body.style.backgroundRepeat = 'no-repeat'; // Do not repeat the image
    document.body.style.backgroundAttachment = 'fixed'; // Fixed background

    // Optional: Reset the background image when the component unmounts
    return () => {
      document.body.style.backgroundImage = null;
      document.body.style.backgroundSize = null;
      document.body.style.backgroundRepeat = null;
      document.body.style.backgroundAttachment = null;
    };
  }, []);

  return (
    <div className="contact-page-container">
      <div className="contact-content">
        <div className="contact-info">
        <div className="contact-item">
          <PhoneIcon className="white-icon" />
            <a href="tel:+9619547611">+961 9 547 611</a>
          </div>
          <div className="contact-item">
          <SmartphoneIcon className="white-icon" />
            <a href="tel:+9613302118">+961 3 302 118</a>
          </div>
          <div className="contact-item">
          <SmartphoneIcon className="white-icon" />
            <a href="tel:+96171996447">+961 71 996 447</a>
          </div>
          <div className="contact-item" >
            <EmailIcon className="white-icon" />
            <a href="mailto:contact@mattafinancialservices.com">
              contact@mattafinancialservices.com
            </a>
          </div>
          <div className="contact-item">
            <PlaceIcon className="white-icon" />
          <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer">
              Centre Laura, Bloc B, 3rd Floor, Voix 13, Byblos, Lebanon
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;