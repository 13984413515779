// App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from './components/HomePage';
import Services from './components/Services';
import Industries from './components/Industries';
import Team from './components/Team';
import ContactUs from './components/ContactUs';
import LayoutWithHorizontalTabs from './components/LayoutWithHorizontalTabs'; // Update this import

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LayoutWithHorizontalTabs />}>
          <Route index element={<HomePage />} />
          <Route path="our-services" element={<Services />} />
          <Route path="our-industries" element={<Industries />} />
          <Route path="our-team" element={<Team />} />
          <Route path="contact-information" element={<ContactUs />} />
        </Route>
      </Routes>
    </Router>
    
  );
}

export default App;
