import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './TabStyles.css'; // Make sure this CSS is set up for the layout
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import AssignmentIcon from '@mui/icons-material/Assignment';
import WorkIcon from '@mui/icons-material/Work';
import logo from '../../public/logo.png';
import { CgMenu } from 'react-icons/cg'; // Hamburger menu icon
import { CgClose } from 'react-icons/cg'; // Close icon

function NavigationTabs() {
  const [isNavVisible, setIsNavVisible] = useState(false);
  const location = useLocation();

  const toggleNav = () => setIsNavVisible(!isNavVisible);

  const tabs = [
    { path: "/", name: "Home", Icon: HomeIcon },
    { path: "/our-services", name: "Our Services", Icon: WorkIcon },
    { path: "/our-industries", name: "Our Industries", Icon: AssignmentIcon },
    { path: "/our-team", name: "Our People", Icon: PeopleIcon },
    { path: "/contact-information", name: "Contact Us", Icon: ContactSupportIcon },
  ];

  const currentTab = tabs.find(tab => tab.path === location.pathname)?.name || 'Home';

  return (
    <div className="tab-container">
      <CgMenu style={{ color: 'white'}} className="hamburger-menu-icon" onClick={toggleNav} />
      
      {!isNavVisible && (
        <div className="current-tab-title">{currentTab}</div>
      )}

      <div className={`mobile-nav ${isNavVisible ? 'visible' : ''}`}>
        <CgClose className="close-icon" onClick={toggleNav} />
        {tabs.map((tab, index) => (
          <NavLink
            key={index}
            to={tab.path}
            className={({ isActive }) => (isActive ? 'nav-item active' : 'nav-item')}
            onClick={toggleNav}
          >
            <tab.Icon />
            <span className="nav-text">{tab.name}</span>
          </NavLink>
        ))}
      </div>
      <ul className="tab-list">
        {tabs.map((tab, index) => (
          <li key={index} className="tab-list-item">
            <NavLink
              to={tab.path}
              className={({ isActive }) => isActive ? 'tab-list-item active' : 'tab-list-item'}
              style={{ color: 'white' }} // Adjust as needed
            >
              {tab.Icon ? <tab.Icon style={{ marginRight: '8px' }} /> : null}
              {tab.name}
            </NavLink>
          </li>
        ))}
      </ul>
      <img src={logo} alt="Logo" className="navbar-logo" />
    </div>
  );
}

export default NavigationTabs;
