import React, { useEffect } from "react";
import image1 from '../../public/res.png';
import image2 from '../../public/hotel.png';
import image3 from '../../public/health.png';
import image4 from '../../public/cons.png';
import image5 from '../../public/agriculture.png';
import image6 from '../../public/oil.png';
import image7 from '../../public/manufacturing.png';
import image8 from '../../public/retail.png';
import image9 from '../../public/realestate.png';
import image10 from '../../public/education.png';
import image11 from '../../public/media.png';
import image12 from '../../public/non-profit.png';
import "./Industries.css";


// Dummy data for the services
const industriesData = [
  {
    title: 'Food and Drink',
    description: 'Our expertise in the Food & Drink industry extends from navigating complex financial structures to providing strategic insights. We help businesses optimize financial performance and compliance while staying attuned to industry-specific challenges.',
    imageUrl:  image1,
  },
  {
    title: 'Hospitality and Tourism',
    description: 'In the dynamic world of Hospitality & Tourism, we offer tailored financial solutions. From tax planning to risk management, our services empower clients to enhance operational efficiency and adapt to evolving market trends.',
    imageUrl:  image2,
  },
  {
    title: 'Agriculture',
    description: 'Partnering with the Agriculture sector, we assist in financial planning, risk mitigation, and sustainable growth. Our team understands the unique challenges of the industry, providing comprehensive support to farmers and agribusinesses.',
    imageUrl:  image5,
  },
  {
    title: 'Oil and Gas',
    description: 'Navigating the intricacies of the Oil & Gas sector, we offer financial and advisory services that align with industry demands. Our team is adept at addressing regulatory complexities and ensuring clients thrive in this ever-changing landscape.',
    imageUrl: image6,
  },
  {
    title: 'Construction',
    description: 'From project financing to risk management, our services in the Construction industry foster growth and success. We collaborate with clients to optimize financial structures, enhance project efficiency, and navigate regulatory frameworks.',
    imageUrl:  image4,
  },
  {
    title: 'Manufacturing',
    description: 'In the competitive Manufacturing landscape, we provide financial strategies that drive efficiency and innovation. Our tailored solutions support manufacturers in optimizing processes, managing costs, and staying ahead in a dynamic market.',
    imageUrl:  image7,
  },
  {
    title: 'Retail',
    description: 'Our comprehensive services in the Retail sector encompass financial management, inventory optimization, and strategic planning. We empower retailers to navigate market dynamics and achieve sustained success in a rapidly evolving industry.',
    imageUrl:  image8,
  },
  {
    title: 'Health Care',
    description: 'Addressing the unique financial challenges of the Health Care sector, we offer solutions that enhance operational efficiency and compliance. Our services support healthcare providers in delivering quality care while maintaining financial stability.',
    imageUrl:  image3,
  },
  {
    title: 'Real Estate',
    description: 'Partnering with the Real Estate industry, we provide financial guidance for property development, investment, and management. Our team helps clients navigate the complexities of real estate transactions and optimize returns.',
    imageUrl:  image9,
  },
  {
    title: 'Education',
    description: ' In the dynamic field of Education, we offer financial and strategic support. From budgeting to compliance, our services empower educational institutions to focus on their core mission while ensuring financial sustainability.',
    imageUrl:  image10,
  }, 
  {
    title: 'Media and Advertising',
    description: 'Our services in the Media & Advertising sector encompass financial planning, revenue optimization, and strategic positioning. We assist clients in navigating the dynamic landscape of media and advertising, ensuring financial success and market relevance.',
    imageUrl:  image11,
  },
  {
    title: 'Not-For-Profit Organizations',
    description: 'Our audit, accounting, and consultancy services cater to non-profit organizations, focusing on financial transparency, compliance, and strategic financial management to support their mission-driven goals.',
    imageUrl:  image12,
  } 
];



function Industries() {
  useEffect(() => {
    // When the component mounts, set the background color of the body
    document.body.style.backgroundColor = "grey"; // Replace with your desired color

    // When the component unmounts, reset the background color
    return () => {
      document.body.style.backgroundColor = ""; // Reset to default color
    };
  }, []);
  return (
    <div className="industries-grid">
      {industriesData.map((industry, index) => (
        <div className="industry-card" key={index}>
          <img src={industry.imageUrl} alt={industry.title} className="industry-image" />
          <div className="industry-info">
            <h3>{industry.title}</h3>
            <p>{industry.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
}


export default Industries;